import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_COUNTER_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (res) => res.data,
  async (e) => {
    await Promise.reject(e.message || e?.response?.data);
  }
);



export const sendCustomText = async ({data,dataFile}) => {
  try{
    let url = `/set-item-text`;

    if(dataFile){
      url+= `?dataFile=${dataFile}`
    }

    return await api.post(url, {data:data}, {
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }catch (e) {
    return e.message
  }
};
export const deleteCustomText = async ({id,dataFile}) => {
  try{
    let url = `/delete-item-text/${id}`;

    if(dataFile){
      url+= `?dataFile=${dataFile}`
    }

    return await api.delete(url, {
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }catch (e) {
    return e.message
  }
};
export const uploadFiles = async ({data,filesInfo,ocrEngine,dataFile,setUploadProgress,convertFile=false}) => {

  let url = `/upload`;
  if(ocrEngine){
    url+=`?ocrEngine=${ocrEngine}`
  }
  if(dataFile){
    url+= url.indexOf('?')!==-1 ? `&dataFile=${dataFile}` : `?dataFile=${dataFile}`
  }
  if(convertFile){
    url+= url.indexOf('?')!==-1 ? `&convertFile=${convertFile}` : `?convertFile=${convertFile}`;
  }

    return await api.post(url, data, {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(filesInfo,percentCompleted);
      },
      timeout: 0,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })


};
