import React from 'react';

const TranslationItemButtons=({item,id,deleteItem,editItem})=>{

    return <>

        {!!(!item.error && item.words) &&
        <span className="upload-edit">
                            <b>{item.words}<span>{item.words===1 ? 'word' : 'words'}</span></b>
                            &nbsp;
                            <b>{item.characters}<span>C.W.S</span></b>
        </span>
        }

        {item.error ?
            <div className="form-group__buttons">
                <div className="upload-content__files-list__options">
                    <button className=" button button-close" onClick={()=>{deleteItem(id)}}/>
                </div>
            </div>
            :
            <div className={`upload-content__files-list__options ${editItem ? 'upload-content__files-list__options-edit' : ''}`}>
                {!!editItem &&
                <button className="button button-edit" onClick={()=>{editItem(id)}}/>
                }
                <button className="button button-close" onClick={()=>{deleteItem(id)}}/>
            </div>
        }




    </>
};
export default TranslationItemButtons;
