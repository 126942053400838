import {Trans} from "react-i18next";


const SummaryButton=(props)=>{

    const {
        currentStep,
        loading,
        handleChangeStep,
        translations,
        stepsData,
    } = props;
    let nextStep = stepsData[currentStep];
    let prevStep = stepsData[currentStep-2];
    const calculationHasError = Object.keys(translations)
        .map(key=>translations[key])
        .filter(item=>item.error).length;


    return <div className="summary__total--button">
        {(currentStep<5 || loading) &&
            <a className='pointer' onClick={(e)=> {
                e.preventDefault();
                handleChangeStep('next')
            }}>{
                loading
                    ?
                    <Trans>Loading...</Trans>
                    :
                    (!!nextStep &&
                        <><span><Trans>Next</Trans>:</span> {currentStep===4 && calculationHasError ? <Trans>GET A QUOTE</Trans> : nextStep.label}</>
                    )
            }</a>
        }
        {(currentStep === 5 && !loading)&&
            <button type={'submit'} form={"payment-form"} className='pointer'>
                <Trans>Place Order</Trans>
            </button>
        }
        {!!prevStep &&
            <div className="summary__back--button">
                <a className='pointer summary__back' onClick={()=>handleChangeStep('prev')}><span><Trans>Back</Trans>: </span>&nbsp;{prevStep.label}</a>
            </div>
        }
    </div>
}
export default SummaryButton;


