
type IRadioButton = {
    name?:string,
    value?:number,
    selected?:number,
    changeValue?:Function,
    label?:string
}

const RadioButton = (props:IRadioButton) => {
    const {name, value, selected, changeValue, label=''} = props;

    return <label className="radio">
        <div className="radio__box">
            <input className="radio__input" type="radio"
                   checked={value === selected}
                   value={value}
                   name={name}
                   onChange={e => changeValue(e)}
            />
            <span className="radio__checkmark"/>
        </div>
        {!!label && 
        <span className="radio__name">{label}</span>
        }
    </label>;
}

export default RadioButton;