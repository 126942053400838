import { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements, Elements,
} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import apiCheckout from "../../api/checkout";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Payment({placeOrder}) {
    const stripe = useStripe();
    const elements = useElements();

    const {t}=useTranslation();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    toast.error(t("Payment succeeded!"));
                    break;
                case "processing":
                    toast.error(t("Your payment is processing."));
                    break;
                case "requires_payment_method":
                    toast.error(t("Your payment was not successful, please try again."));
                    break;
                default:
                    toast.error(t("Something went wrong."));
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        console.log('stripe payment ! ')
        await placeOrder({noPayment:false,emailChanged:false,elements:elements, stripe:stripe})

    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
        </form>
    );
}

export default function  StripePayment({placeOrder,...props }){
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(true);
    const {t}=useTranslation();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        apiCheckout.post({
            amount:props.amount ? (parseFloat(props.amount)*100).toFixed() : 1000,
            email:props.data.email,
            name:props.data.name,
        },"/create-payment-intent", )
            .then((result) => {
                console.log('result',result)
                setLoading(false);
                setClientSecret(result.clientSecret)
            });
    }, []);


    return (
        <div className="App">
            {(clientSecret && !loading && stripePromise) ? (
                <Elements options={{
                    clientSecret,
                    appearance:{
                        theme: "stripe",
                    }
                }}
                          stripe={stripePromise}>
                    <Payment placeOrder={placeOrder} {...props} />
                </Elements>
            ):
                <p>{t("Loading...")}</p>
            }
        </div>
    );
}