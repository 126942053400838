import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers/index";
import rootSaga from "./sagas";

const saga = createSagaMiddleware();

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION__'] as typeof compose || compose;

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(saga),
      composeEnhancers
      ? composeEnhancers()
      : (f) => f
  )
);

saga.run(rootSaga);

export default store