import {   takeEvery, put,select } from "redux-saga/effects";
import {
  ADD_FILES,
  ADD_TRANSLATION,
  ADD_TRANSLATION_ITEM,
  DELETE_TRANSLATION,
  DELETE_TRANSLATION_ITEM, SET_CROSS_PARAMS, UPDATE_COUNTER,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_ITEM,
} from "../actions/types";
import {safe} from "./errorHandler";
import {deleteCustomText, uploadFiles} from "../../services/api";
import uniqId from "../../utils/uniqId";
import store from "../index";
import {setCalculating, updateTranslationItem} from "../actions";

const cancelFileUploadProgress=function (filesInfo,error){
  if(filesInfo){
    for (let key of Object.keys(filesInfo)){

      let newFileData = {
        ...filesInfo[key],...{error:error}
      };
      store.dispatch(updateTranslationItem(newFileData))
    }
  }
};
const setUploadProgress=function (filesInfo,percentCompleted){


    if(filesInfo){
      for (let key of Object.keys(filesInfo)){

        let newFileData = {
         ...filesInfo[key],...{uploadProgress:percentCompleted}
        };
        store.dispatch(updateTranslationItem(newFileData))
      }
    }


};

const addFiles = function* ({ payload }) {
  if(payload && payload.length){
    store.dispatch(setCalculating(true))
    let filesInfo={};
    for(let file of payload) {
      let fileData:any = {
        type:file.data.type,
        name:file.name,
        data: file.data
      };
      fileData.id = uniqId();
      filesInfo[fileData.id] = fileData;
      yield put({type: ADD_TRANSLATION_ITEM, payload: fileData});
    }

    for(let key of Object.keys(filesInfo)) {

      let info = {[key]:filesInfo[key]};

      let itemData =  new FormData();
      itemData.append(key ,filesInfo[key].data);
      try {
        const  ocrEngine = yield select((state) => state.translation.ocrEngine);
        const  dataFile = yield select((state) => state.translation.dataFile);
        let convertFile = filesInfo[key] && filesInfo[key].type && filesInfo[key].type==='application/msword';
        let result = yield uploadFiles({data:itemData,filesInfo:info,ocrEngine:ocrEngine,dataFile:dataFile,setUploadProgress:yield setUploadProgress,convertFile:convertFile});


        if(result && result.files){
          for(let key of Object.keys(result.files)){
            yield put({type: UPDATE_TRANSLATION_ITEM, payload: result.files[key]});
          }
        }
        yield put({type: SET_CROSS_PARAMS, payload: result});
        yield put({type: UPDATE_COUNTER});
      }catch (error) {
        cancelFileUploadProgress(info,error);
      }
    }
    store.dispatch(setCalculating(false))
  }

};

const addTranslation= function* ({ payload }) {
  console.log('')

  yield put({type: ADD_TRANSLATION_ITEM, payload: payload});
  yield put({type: UPDATE_COUNTER});

};
const deleteTranslation= function* ({ payload }) {
  const  dataFile = yield select((state) => state.translation.dataFile);
  let result = yield deleteCustomText({id:payload,dataFile });
  yield put({type: SET_CROSS_PARAMS, payload: result});
  yield put({type: DELETE_TRANSLATION_ITEM, payload: payload});
  yield put({type: UPDATE_COUNTER});
};
const updateTranslation= function* ({ payload }) {

  yield put({type: UPDATE_TRANSLATION_ITEM, payload: payload});
  yield put({type: UPDATE_COUNTER});
};




const translationSagas = [
  takeEvery(ADD_TRANSLATION, safe(addTranslation)),
  takeEvery(DELETE_TRANSLATION, safe(deleteTranslation)),
  takeEvery(UPDATE_TRANSLATION, safe(updateTranslation)),
  takeEvery(ADD_FILES, safe(addFiles)),
];

export default translationSagas;
