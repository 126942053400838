const getFileIconType=(item)=>{

    let string = item.file_name || item.name|| item;

    let extension = string?.split('.')?.pop();

    if(extension==='jpeg')
        extension='jpg';

    let file : any = '';

    try{
        file =   require(`../images/fileIcons/${extension.toUpperCase()}.svg`);
    }catch (e) {
        file =   require(`../images/fileIcons/OTHER.svg`);
    }
    return file.default ? file.default : file;
};
export default getFileIconType;