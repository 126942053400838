import React,{useSelector} from "react-redux";
import { withTranslation} from "react-i18next";
import SelectInput from "../common/blocks/SelectInput";
import {RootState} from "../../redux-store/reducers";
import {useEffect} from "react";
import moment from "moment";


const Languages=({i18n,className='select--transparent header__dropdown-lang'})=>{

    const app_languages = useSelector((state:RootState) => state.init.app_languages);

    useEffect(()=>{
        loadMomentLocale(i18n.language);
    },[i18n])



    const changeLanguage=(value)=>{
        let language  = app_languages.find(item=>item.id===value);
        if(language)
            i18n.changeLanguage(language.code);


        window.location.reload();
    };



    return <div className="language__picker">
        {!!app_languages &&
        <>
            <SelectInput value={app_languages.find(item => item.code === i18n.language)} name={'language'}
                         changeHandler={changeLanguage} labelImg={"/images/icons/planet.svg"} options={app_languages}
                         className={className} arrayName={undefined} arrayIndex={undefined} placeholder={undefined}
                         search={undefined} required={undefined} nameKey={undefined} defaultOption={undefined}
            />
        </>}
    </div>
};



export const loadMomentLocale=function (code){
    /**
     * Load moment locale
     */
    try {
        if(code!=='en'){
            require(`moment/locale/${code}`);
            moment.locale(code)
        }
    }catch (e){
        console.log(e)
    }
}


export default withTranslation()(Languages)
