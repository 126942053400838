
const uploadFiles = ({files, addAllFiles}) => {
    // const newFiles = [];
    for (let i = 0; i < files.length; i++) {
        // newFiles.push({ data: files[i], name: files[i].name });

        addAllFiles([{ data: files[i], name: files[i].name }]);

    }
    // console.log('newFiles',newFiles)



};
export default uploadFiles;
