import {
    SET_APP_LANGUAGES,

} from "../actions/types";

const initialState = {
    app_languages: false,
};

export default function(state = initialState, action ) {
    switch (action.type) {
        case SET_APP_LANGUAGES:
            return {
                ...state,
                app_languages: action.payload
            };
        default:
            return state;
    }
}
