import React, {useState,useRef} from 'react';
import useOutsideClick from "../../utils/useOutsideClick";
import {useTranslation} from "react-i18next";

const AddFilesBtn=({saveFiles,text='Add files', className='btn-add'})=>{

    const [btnOpen,setBtnOpen] = useState(false);



    const ref = useRef();

    useOutsideClick(ref, () => {
        setBtnOpen(false);
    });


    const {t} = useTranslation();

    return <div className={`${className} ${btnOpen ? 'btn-add_opened' : ''} `}>
        <div className="btn-add__header">
            <div className="btn-add__input btn-add__input_add">
                <label htmlFor="file-upload" className="btn-add__input-label">{text}</label>
                <input type="file" id="file-upload" name="myfile"
                       multiple={true}
                       onChange={(e) =>{
                           setBtnOpen(false);
                           saveFiles(e.target.files);
                       }}
                       style={{
                           visibility: "hidden",
                           position: "absolute",
                           zIndex: "-10"
                       }}/>
            </div>
            <div onClick={()=>setBtnOpen(!btnOpen)} className="btn-add__icon"/>
        </div>
        <div className="btn-add__dropdown">
            <ul className="btn-add__list">
                <li className="btn-add__item">
                    <div className="btn-add__input">
                        <label htmlFor="file-upload-2" className="btn-add__input-label">{t("From computer")}
                        </label>
                        <input type="file" id="file-upload-2" name="myfile"
                               multiple={true}
                               onChange={(e) =>{
                                   setBtnOpen(false);
                                   saveFiles(e.target.files);
                               }}
                               style={{
                                   visibility: "hidden",
                                   position: "absolute",
                                   zIndex: "-10"
                               }}/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
};
export default AddFilesBtn;
