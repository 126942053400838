export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const lowerCaseFirstLetter = (string) => {
    return string.charAt(0).toLocaleLowerCase() + string.slice(1);
}

export const getLabelFromString = (string:string):string => {

    let array = string.split('_');
    array[0] = capitalizeFirstLetter(array[0]);
    return array.join(' ');
};

export const removeFromArrayByValue = (array, item) => {
    let index = array.indexOf(item);
    if (index > -1)
        array.splice(index, 1);
    return array;
};

export const formatDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
export const daysCalculate = (start, end) => {
    const diff = end.getTime() - start.getTime()
    return Math.ceil(diff/ (1000 * 3600 * 24)) - 1;
}

export const uuidv4 = () => {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
