import React  from "react";
import {FileDrop} from "react-file-drop";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {addFiles, deleteTranslationItem} from "../../../../redux-store/actions";
import uploadFiles from "../../../../utils/uploadFiles";
import TranslationItems from "../../../pages/result/TranslationItems";
import AddFilesBtn from "../../../pages/AddFilesBtn";



const UploadBlock = ({showUpload,currentFiles,addAllFiles,deleteTranslationItem,translations,onSave,onDelete,}) => {
    const {t} = useTranslation();

    const saveFiles=async(files)=>{
        uploadFiles({files,addAllFiles})

        if(onSave){
            return onSave(files);
        }

    };


    let files =currentFiles;
    return <FileDrop onDrop={(files,e) => {
        saveFiles(files)
    }}>
        {!!(showUpload || files) &&
            <div className={"  " + ((!files ||  !files.length) ? " upload-content__drag-drop upload-content__drag-drop__old" : " upload-content__files")}>
                {(files && files.length)
                    ?
                    <>
                        <div className="upload-content__files-list">
                            <TranslationItems translations={translations} deleteTranslationItem={(id,index)=>{
                                onDelete(index);
                                deleteTranslationItem(id)
                            }
                            }/>
                        </div>
                        <div className="upload-content__files-upload__button">
                            <AddFilesBtn saveFiles={saveFiles} text={t("Add")} className={'btn-add btn-add_second'}/>
                        </div>
                    </>
                    :
                    <div className="wrap-btn-add">
                        <AddFilesBtn saveFiles={saveFiles} text={t('Add files')} />
                    </div>

                }
            </div>
        }
    </FileDrop>
};

const mapDispatchToProps = (dispatch) => ({
    deleteTranslationItem: (data) => dispatch(deleteTranslationItem(data)),
    addAllFiles: (data) => dispatch(addFiles(data)),
});
const mapStateToProps = ({translation:{items}}) => ({
    translations:items
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadBlock);

