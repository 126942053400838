import React, {useState} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import {useTranslation} from "react-i18next";
const MY_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


const LocationSearch=({country,changeData})=>{

    const {t} = useTranslation();
    
    const [search,setSearch] = useState('')
    const [value,setValue] = useState('')



    const handleInputChange = e => {
        setValue(e.target.value);
        setSearch(e.target.value);
        changeData('address',e.target.value);
    };

    const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {


        changeData('address',geocodedPrediction.formatted_address);
        setValue(geocodedPrediction.formatted_address);
        setSearch('');
    };

    const handleNoResult = () => {
        console.log("No results for ", search)
    };

    const handleStatusUpdate = status => {
    };


    return <ReactGoogleMapLoader
        params={{
            key: MY_API_KEY,
            libraries: "places,geocode",
        }}
        render={(googleMaps, error) =>
        {
            return (googleMaps&&googleMaps.places) ? (
                    <ReactGooglePlacesSuggest
                        googleMaps={googleMaps}
                        autocompletionRequest={{
                            input: search,
                            types: ['address'],
                            componentRestrictions: {country:(country) ? country.code : false}
                            // Optional options
                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                        }}
                        // Optional props
                        onNoResult={handleNoResult}
                        onSelectSuggest={handleSelectSuggest}
                        onStatusUpdate={handleStatusUpdate}
                        displayPoweredByGoogle={false}
                        customRender={prediction => {
                            return <div className="select__option js-select-option">
                                {prediction
                                    ? prediction.description
                                    : t("No results found")}
                            </div>
                        }}
                    >
                        <div className={"field__custom"}>
                            <input
                            type="text"

                            value={value}
                            name={'address'}
                            placeholder={t("address")}
                            autoComplete="__"
                            className="input-style mb20"
                            onChange={handleInputChange}
                            required={true}
                        />
                        </div>

                    </ReactGooglePlacesSuggest>
            ):''
        }
        }
    />
}
export default LocationSearch;