import React from 'react';
import CustomType from "./CustomType";
import FileType from "./FileType";


const TranslationItem=({item,id,deleteItem,editItem})=>{



    switch (item.type) {
        case 'custom':
            return <CustomType id={id}  item={item} editItem={editItem} deleteItem={deleteItem}/>;
        default:
            return <FileType id={id} item={item} deleteItem={deleteItem}/>
    }

};
export default TranslationItem
