import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import { Player } from '@lottiefiles/react-lottie-player';
import {connect} from "react-redux";
import {toast} from "react-toastify";

const SuccessPage = ({data,translations,placeOrder,loading}) => {

    const {t} = useTranslation();

    const [edit,setEdit] = useState(false);
    const [email,setEmail] = useState(data.email || new URLSearchParams(window.location.search).get(
        "email"
    ));

    const calculationHasError = new URLSearchParams(window.location.search).get(
        "calculationHasError"
    ) ||  Object.keys(translations)
        .map(key=>translations[key])
        .filter(item=>item.error).length;

    const changeEmail= async () => {


        let result = await placeOrder({noPayment:true, emailChanged:email})
        setEdit(false);
        if(result && result.success){
            toast.success(t("Email successfully changed!"));
        }
    }

    return <div className="order__confirm">
        <div className="confirm__image">
            <Player
                src="/lottie/mangif.json"
                style={{ height: '300px', width: '300px' }}
                autoplay={true}
                loop={true}
                controls={false}
            />
        </div>
        <div className="order__info">
            {calculationHasError
                ?
                <h2>{t("Success!")} <br/> <span>{t("Your quote request is on way to a member of out team " +
                    "who will contact you with the price and terms of your translation project as soon as possible. We will aim to respond to you with 1 hour." +
                    "Thank you for your patience - we look forward to working with you.")}</span></h2>
                :
                <h2>{t("Order Confirmed!")} <br/> <span>{t("Relax and let us get the job done…")}</span></h2>
            }
            {/*<p>Your order number: VKV-431</p>*/}
            <div className="order__details">
                {!calculationHasError &&
                    <>
                        <p>{t("The order details have been sent to")}</p>
                        <div className="order__email--wrapper">
                            {edit
                                ?

                                <div className="order__email">

                                    <input type={"email"} name="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    {!loading ?
                                        <a href="#"
                                           onClick={(e)=>{
                                               e.preventDefault();
                                               changeEmail();
                                           }
                                           }
                                        >{t("SAVE")}</a>:
                                        <a href="#"
                                           onClick={(e)=>{
                                               e.preventDefault();
                                           }
                                           }
                                        >{t("LOADING...")}</a>
                                    }

                                </div>
                                :
                                <div className="order__email">
                                    <p>{email} </p>
                                    <a href="/" onClick={(e)=>{
                                        e.preventDefault();
                                        setEdit(true);
                                    }
                                    }>{t("CHANGE")}</a>
                                </div>
                            }
                        </div>
                        <span>{t("If you don’t see the email in your main inbox, please check your spam folder.")} </span>
                    </>
                }

                <div className="back__order">
                    <a href="/">{t("GO TO HOME PAGE")}</a>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({translation:{items}}) => ({
    translations:items,
});
export default connect(mapStateToProps)(SuccessPage);

