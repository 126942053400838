import Security from "./Security";
import checkedicon from "../images/checkgreenlight.svg";
import {Trans, useTranslation} from "react-i18next";
import {TurnAround} from "../containers/FourthStep";
import {connect} from "react-redux";
import ManualIcon from "./pages/result/ManualIcon";
import SummaryButton from "./common/blocks/SummaryButton";

const Sidebar=(props)=>{

    const {stepsData,data,currentStep,pricing,totalPrice,translations} = props;



    const {t} = useTranslation();


    const calculationHasError = Object.keys(translations)
        .map(key=>translations[key])
        .filter(item=>item.error).length;


    return <div className="guest__content--side">
        <div className="guest__summary">
            <div className="summary__head">
                <h2>{stepsData[currentStep-1].sidebarHead}</h2>
            </div>
            {currentStep===1 ?
                <div className="summary__project--info">
                    <ul>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Guaranteed USCIS Acceptance</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Certificate of Translation Accuracy</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>100% Human Translation</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Secure Document Handling</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Electronic PDF Delivered via E-mail</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Physical Copies Available</Trans></li>
                        <li><span><img src={checkedicon} alt="checked" /></span> <Trans>Notarization Available</Trans></li>
                    </ul>
                </div>
                :
                <div className="summary__project">
                    {(pricing && pricing.languages_from)
                        ?
                        <>
                            <div className="summary__project--wrapper">
                                <div className="summary__element">
                                    <div className="summary__left">
                                        <h2>{pricing.languages_from?.map(item=>item.label).join(', ')} - {pricing.languages_to?.map(item => item.label).join(', ')}</h2>
                                        <ul>
                                            <li>{pricing?.totalResults[pricing.filesMatt]} {pricing.filesMatt}</li>
                                        </ul>
                                    </div>

                                    <div className="summary__right">
                                        {calculationHasError
                                            ?
                                            <ManualIcon/>
                                            :
                                            <p>$ {parseFloat(pricing.price).toFixed(2)}</p>

                                        }

                                    </div>

                                </div>
                                {data.options && Object.keys(data.options).map((key)=>
                                    <div className="summary__element" key={key}>
                                        <div className="summary__left">
                                            <h2>{data.options[key].parentName}</h2>
                                            <ul>
                                                <li>{data.options[key].name}</li>
                                            </ul>
                                        </div>
                                        <div className="summary__right">
                                            <p>{data.options[key]?.totalPrice ? `$ ${data.options[key].totalPrice.toFixed(2)}` : t('FREE')}</p>
                                        </div>

                                    </div>
                                )}
                                {data.turnaround_time &&
                                    <div className="summary__element">
                                        <div className="summary__left">
                                            <h2>{t("Turaround time")}</h2>
                                            <ul>
                                                <li><TurnAround data={data.turnaround_time} type={undefined} /></li>
                                            </ul>
                                        </div>
                                        {data.turnaround_time.urgency_fee &&
                                            <div className="summary__right">
                                                <p>{data.turnaround_time.urgency_fee}%</p>
                                            </div>
                                        }


                                    </div>
                                }
                            </div>
                        </>
                        :
                        <div className="summary__estimation">
                            <p>{t("The price will be estimated in the next step.")}</p>
                        </div>

                    }

                </div>
            }

            <div className="summary__total">
                {!!(totalPrice &&!calculationHasError) &&
                <div className="summary__total--info">
                    <span>Total:</span>
                    <p className="summary__empty">${parseFloat(totalPrice).toFixed(2)}</p>
                </div>
                }
                <SummaryButton {...props}/>
            </div>
        </div>
        <Security />
    </div>

}



const mapStateToProps = ({translation:{calculating,items}}) => ({
    calculating:calculating,
    translations:items,
});
export default connect(mapStateToProps)(Sidebar);

