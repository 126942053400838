import steps from '../constans/steps'
import { useState, useEffect } from 'react'
import progressdone from '../images/progressdone.svg'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import FourthStep from './FourthStep'
import FifthStep from './FifthStep'
import Sidebar from "../components/Sidebar";
import apiCheckout from "../api/checkout";
import SuccessPage from "./SuccessPage";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {isEmail} from "../functions/isEmail";
import {connect} from "react-redux";


declare var window: any;
const checkActive = (active) => active ? " active__progress" : ""
const checkDone = (done) => done ? " done__progress" : "";
interface PaymentIntent {
  id: string|null;
  status: string|null;
}

const PaymentIntentDefault = {
  id: null,
  status: null
}

const OrderForm = ({calculating,translations}) => {

  const {t} = useTranslation();


  const success = new URLSearchParams(window.location.search).get(
      "success"
  );
  const paymentTesting = new URLSearchParams(window.location.search).get(
      "paymentTesting"
  );

  const [stepsData, setStep] = useState(steps)
  const [currentStep, setCurrentStep] = useState(success ? 6 : (paymentTesting ? 5 : 1))
  const [data, setData] = useState({
    options: undefined,
    turnaround_time: undefined,
    name: undefined,
    email: undefined,
    languages_from: undefined,
    languages_to: undefined
  });
  const [files, setFiles] = useState([]);
  const [pricing, setPricing] = useState({
    price: undefined,
    options: undefined
  });
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(paymentTesting ? 5 : 0);
  const [calculated, setCalculated] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [paymentIntent,setPaymentIntent] = useState<PaymentIntent>(PaymentIntentDefault);

  const addFiles = (newFiles) => {

    let setNewFiles = [...files];
    for (let i = 0, l = newFiles.length; i < l; i++) {
      setNewFiles.push(newFiles[i]);
    }
    setFiles(setNewFiles);
  }

  useEffect(()=>{
    let total:number = 0;
    if(data.options){
      Object.keys(data.options).forEach(key=>{
        let option = data.options[key];
        if(option && option.totalPrice){
          total+=option.totalPrice;
        }
      })

      total= total + ((pricing&&pricing.price) ? parseFloat(pricing.price) : 0);


      if(data && data.turnaround_time && data.turnaround_time.urgency_fee){
        total = total+ total*data.turnaround_time.urgency_fee/100;
      }
      total = parseFloat(typeof total==='string' ?  parseFloat(total).toFixed(2) : total.toFixed(2));

      setTotalPrice(total);
    }
    if(orderId){
      setOrderId(false);
    }
    if(paymentIntent.id){
      setPaymentIntent(PaymentIntentDefault);
    }

  },[data.options,data.turnaround_time]);


  const handleChangeStep = async (type,step)  => {
    if(loading)
      return;


    const calculationHasError = Object.keys(translations)
        .map(key=>translations[key])
        .filter(item=>item.error).length;


    if(currentStep===4 && calculationHasError){
      let result = await placeOrder({noPayment:true, emailChanged:false});
      if(!result.success){
        return toast.error(t("There was an error in server."))
      }else{
        return setCurrentStep(6);
      }

    }


    let newArr = [...stepsData]
    const update =type ? ( type === 'next' ? currentStep + 1 : currentStep - 1 ): step;


    let check = checkStepDataRequired(update);

    if(!check)
      return;

      let result = await estimatePrice(update);
      if(!result)
        return;

      newArr = newArr.map((item,index)=>{
        item.active = update===index+1;
        item.done = index<update;
        return item;
      })
      setStep(newArr)
      setCurrentStep(update )

  }



  const checkStepDataRequired = (step) => {
    let check = true;

    if(step<currentStep)
      return check;

    switch (true){
      case currentStep===1 && (!data.name || !data.email || !isEmail(data.email)):
        toast.error(t("Please enter both name and email."));
        check = false
            break;
      case currentStep===2 && calculating:
        toast.error(t("Please wait, files calculating!"));
        check = false
            break;
      case currentStep===2 && ((!data.languages_from || !data.languages_to || !data.languages_to.length ) && (!files || !files[0])):
        toast.error(t("Please enter languages and upload files."));
        check = false
            break;
      case currentStep===2 && ((!data.languages_from || !data.languages_to || !data.languages_to.length )):
        toast.error(t("Please enter languages!"));
        check = false
            break;
      case currentStep===2 && (!files || !files[0]):
        toast.error(t("Please upload files!"));
        check = false
            break;
      case !!(currentStep===2 && data.languages_to.find(item=>item.id===data.languages_from)?.id):
        toast.error(t('Target languages should not include source language!'));
        check = false
            break;
      case currentStep===4 && (!data.options || Object.keys(data.options).length!==pricing.options.length):
        toast.error(t("Please select all options"));
        check = false
            break;

    }
    if(!check){
      check = false;
    }

    return  check;
  }

  const estimatePrice=async (nextStep)=>{
    if (currentStep===2 && nextStep===3 && !calculated){

      let formData = new FormData();
      formData.append('languages_from',data.languages_from);

      data.languages_to.forEach((item,index)=>{
        formData.append(`languages_to[${index}]`,item?.id);
      })
      formData.append('files',JSON.stringify(Object.keys(translations).map(key=>translations[key])));


      setLoading(true);
      let result  = await apiCheckout.put(formData,'/calculate');
      setLoading(false);
      if(result.price){
        setTotalPrice(result.price ? parseFloat(result.price).toFixed(2) : result.price)
      }
      setPricing(result);

      setData({...data,...{pricing_id:result.pricing_id}, ...{pricing:result}});

      setCalculated(true);




      return !!result.price;
    }
    return true;
  }

  const changeData = (name,value) => {
    let newData = {...data};

    newData[name] = value;
    setData(newData);
  }
  const deleteFile = (fileIndex) => {

    let newFiles = [...files];
    newFiles = newFiles.map((fileItem,index)=>{
       if(index !== fileIndex){
         return fileItem;
       }
    })
    newFiles = newFiles.filter(fileItem=>fileItem);
    setFiles(newFiles);
    setCalculated(false);

  }

  const placeOrder = async({noPayment=false,emailChanged=false,elements=false,stripe=false,paypal=false}) => {
    setLoading(true);
    console.log('paypal',paypal);

    try {

      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
      }


      for (let key in data) {
        if (data[key]) {
          if (Array.isArray(data[key])) {
            if (data[key].length > 0)
              formData.append(key, JSON.stringify(data[key]))
          } else {
            if(typeof data[key] === 'object' && data[key] !== null){
              formData.append(key, JSON.stringify(data[key]))

            }else{
              formData.append(key, data[key])
            }
          }
        }
      }
      formData.append("price", totalPrice.toFixed(2).toString())
      if(emailChanged){
        changeData('email',emailChanged);
        formData.delete("emailChanged")
        formData.delete("email")
        if(typeof emailChanged==='string'){
          formData.append("emailChanged", emailChanged)
          formData.append("email", emailChanged)
        }
      }


      if(noPayment){
        formData.append("noPayment", '1')
      }


      const searchParams = new URLSearchParams(window.location.search)
      // Log the values
      searchParams.forEach(function(value, key) {
        console.log(value, key);
        formData.append(key, value)
      });

      let orderResult
      if(!orderId){
        orderResult = await apiCheckout.post(formData,`/create`);
        if(!orderResult || !orderResult.order){
          setLoading(false);
          return false;
        }
        setOrderId(orderResult.order.id);
      }
      
        if(noPayment){
          setLoading(true);
          let result =  await apiCheckout.post({},`/send/${orderId || orderResult?.order?.id}`);
          setLoading(false);
          return result;
        }else{
          if(paymentIntent.id && paymentIntent.status==='succeeded'){
            return sendEmail();
          }
          if(stripe && typeof stripe !=='boolean'){
            // @ts-ignore

            let paymentResult = await stripe.confirmPayment({
              elements,
              redirect :'if_required'
            });
            
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if(paymentResult.error){
              console.log('error',paymentResult.error);
              if (paymentResult.error && paymentResult.error.type === "card_error" || paymentResult.error.type === "validation_error" || paymentResult.error.type === "invalid_request_error") {
                toast.error(paymentResult.error.message)
              } else {
                toast.error(t("An unexpected error occurred."))
              }
            }else{

              if(paymentResult.paymentIntent){
                setPaymentIntent(paymentResult.paymentIntent);
              }
              return sendEmail(orderResult?.order?.id,paymentResult.paymentIntent);
        
            }
          }
          if(paypal){
            return sendEmail(orderResult?.order?.id,paypal);
          }
        }
      setLoading(false);
    }catch (e){
      console.log('e',e)
      setLoading(false);
    }

  }

  const sendEmail=async(id?:undefined|number,paymentIntentNew?:PaymentIntent|any)=>{
    let result = await apiCheckout.post({
      paymentData:{
        paymentIntent:paymentIntent.id ? paymentIntent : paymentIntentNew,
      },
    },`/send/${orderId||id}`);

    if(result.success){
      // return window.location.href = `${process.env.REACT_APP_FRONTEND_URL}?success=true&email=${data.email}`
      setLoading(false);
      await handleChangeStep(false,6);

      if(typeof window !=='undefined' && typeof window.dataLayer !=='undefined') {
        window.dataLayer.push( {
          'event': 'order',
          'value': totalPrice.toFixed(2),
          'currency': 'USD',
          'transaction_id': orderId||id
        });
      }
    }
    setLoading(false);
    return result;
  }


  const stepsComponent = {
    1: <FirstStep
        data={data}
        changeData={changeData}
        handleChangeStep={handleChangeStep}
        stepsData={stepsData}
        currentStep={currentStep}
        pricing={pricing}
        loading={loading}
        totalPrice={totalPrice}
        translations={translations}
    />,
    2: <SecondStep
        data={data}
        changeData={changeData}
        addFiles={addFiles}
        setCalculated={setCalculated}
        files={files}
        onDelete={deleteFile}
        handleChangeStep={handleChangeStep}
        stepsData={stepsData}
        currentStep={currentStep}
        pricing={pricing}
        loading={loading}
        totalPrice={totalPrice}
        translations={translations}
    />,
    3: <ThirdStep data={data} changeData={changeData}  />,
    4: <FourthStep data={data} changeData={changeData}  pricing={pricing}/>,
    5: <FifthStep data={data} changeData={changeData}
                  totalPrice={totalPrice}
                  placeOrder={placeOrder}
                  loading={loading}
    />,
    6: <SuccessPage data={data} loading={loading} placeOrder={placeOrder}/>,
  }


  const Step = () => {
    return stepsComponent[currentStep]
  }
  

  return (
    <div className={`checkout__long simplified__block ${paymentTesting ? ' paymentTesting ':''}`}>
      {currentStep!==6 &&
      <div className="checkout__progress--section">
        {stepsData && stepsData.map((item) => {
          return (
            <div onClick={(e)=>{
              handleChangeStep(false,item.id)
            }} key={item.id} className={"element__progress--wrapper" + checkActive(item.active) + checkDone(item.done)}>
              <div className={"element__progress" + checkActive(item.active) + checkDone(item.done)}>
                {!item.active && item.done
                  ? <span><img src={progressdone} /></span>
                  : <span>{item.id}</span>
                }
                <p>{item.label}</p>
              </div>
            </div>
          )
        })}
      </div>}

      <div className={"content__guest "+( !!currentStep && `step-${currentStep}`)}>

        {Step()}
        {currentStep!==6 &&
            <Sidebar
                handleChangeStep={handleChangeStep}
                stepsData={stepsData}
                data={data}
                currentStep={currentStep}
                pricing={pricing}
                loading={loading}
                totalPrice={totalPrice}
            />
        }

      </div>
    </div>
  )
}


const mapStateToProps = ({translation:{calculating,items}}) => ({
  calculating:calculating,
  translations:items,
});
export default connect(mapStateToProps)(OrderForm);

