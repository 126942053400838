export const ADD_TRANSLATION_ITEM = "ADD_TRANSLATION_ITEM";
export const UPDATE_TRANSLATION_ITEM = "UPDATE_TRANSLATION_ITEM";
export const DELETE_TRANSLATION_ITEM = "DELETE_TRANSLATION_ITEM";
export const SET_APP_LANGUAGES = "SET_APP_LANGUAGES";

export const ADD_TRANSLATION = "ADD_TRANSLATION";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const DELETE_TRANSLATION = "DELETE_TRANSLATION";

export const UPDATE_COUNTER = "UPDATE_COUNTER";

export const ADD_FILES = "ADD_FILES";

export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_OCR_ENGINE = "SET_OCR_ENGINE";
export const SET_DATA_FILE = "SET_DATA_FILE";
export const SET_CALCULATING = "SET_CALCULATING";
export const SET_CROSS_PARAMS = "SET_CROSS_PARAMS";
