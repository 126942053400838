import {useState} from "react";
import {useTranslation} from "react-i18next";


const ThirdStep = ({
                     changeData,
                     data,
}) => {

  const {t} = useTranslation();
  const [show,setShow] = useState(false);

  return (
    <>
      <div className="guest__content--main">
        <div className="translation__correct--wrapper">
          <h2>{t("Would you like to provide any additional information for the translator (like correct spelling for names)?")}</h2>
          <div className="information__checkbox">
            <div className="row-group">
              <div className="row-group__item">
                <label className="radio" onClick={()=>setShow(true)}>
                  <div className="radio__box">
                    <input className="radio__input"
                           checked={show}
                           type="radio" value="yes" name="morematerials" data-radio-toggle="" />
                    <span className="radio__checkmark"/>
                  </div><span className="radio__name">{t("Yes")}</span>
                </label>
              </div>
              <div className="row-group__item">
                <label className="radio" onClick={()=>setShow(false)}>
                  <div className="radio__box">
                    <input
                        checked={!show}
                        className="radio__input" type="radio" value="no" name="morematerials"/><span className="radio__checkmark"/>
                  </div><span className="radio__name">{t("No")}</span>
                </label>
              </div>
            </div>
          </div>
          {!!show &&
              <div className="translation__correct--field">
                <div className="field__custom">
                  <p>{t("Additional information")}:</p>
                  <textarea
                      cols={30}
                      rows={10}
                      onChange={e=>{
                        changeData('spelling_info',e.target.value)
                      }}
                  >{data.spelling_info}</textarea>
                </div>
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default ThirdStep