import {
	PayPalScriptProvider,
	PayPalButtons,
} from "@paypal/react-paypal-js";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormLoading from "../common/blocks/FormLoading";

const currency = "USD";

export default function PaypalPayment({placeOrder,amount,loading}) {
	const {t} = useTranslation();

	const submitForm=(e)=>{
		e.preventDefault();
		toast.info(t("Click PayPal button to proceed!"))
	}
	if(loading){
		return <FormLoading loadingText={t("Loading...")}/>
	}


	return (
		<form id={"payment-form"} onSubmit={submitForm}>
			<PayPalScriptProvider 
			options={{ 
				"client-id": process.env.REACT_APP_PAYPAL_CLIENTID }}>
				<PayPalButtons 
					style={{ layout: "horizontal" }}
					//@ts-ignore
					
					createOrder={(data, actions) => {
						return actions.order
							.create({
								purchase_units: [
									{
										amount: {
											currency_code: currency,
											value: amount,
										},
									},
								],
							})
							.then((orderId) => {
								// Your code here after create the order
								return orderId;
							});
					}}
					onApprove={function (data, actions) {
						return actions.order.capture().then(function () {
							console.log('onApprove',data)
							placeOrder({noPayment:false,emailChanged:false,elements:false, stripe:false,paypal:data})
							// Your code here after capture the order
						});
					}}

					onError={function (err) {
						toast.error(err.message)
					}}

					
				/>
			</PayPalScriptProvider>
		</form>
	);
}