// Services API
const {ApiCore} = require("./utilities/api-core");
const url = 'checkout';
const plural = 'checkout';
const single = 'checkout';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiCheckout = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: true,
    patch: false,
    remove: false,
    url: url,
    plural: plural,
    single: single
});

export default apiCheckout;
