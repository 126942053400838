import locker from '../images/locker.svg'
import security from "../images/securityimage.png"
import {useTranslation} from "react-i18next";

const Security = () => {
    const {t} = useTranslation();

  return (
    <div className="guest__security">
      <div className="guest__security--head">
        <h2><span><img src={locker} alt="locker" /></span>{t("Secure and Private")}</h2>
      </div>
      <div className="security__info">
        <p>{t("All data processed by our site, including uploaded files, is encrypted. Only essential staff with signed NDAs have access to your information.")}</p>
      </div>
      <div className="security__image">
        <img src={security} alt="securityimage" />
      </div>
    </div>
  )
}

export default Security